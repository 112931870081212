import React from "react"
import {Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import "../styles/about.css"
import Image from "gatsby-image"
import logoVector from '../../content/assets/logo-vector.svg';
import { MDXRenderer } from 'gatsby-plugin-mdx'

const AboutTemplate = ({ data, location }) => {
  const post = data.mdx
  return (
    <Layout location={location}>
      <SEO
      title="about"
      />
      <div className="headshot-container">
        <div className="title-container">
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <img
              src={logoVector}
              alt={"causalflows-logo"}
              style={{
                width: 200,
                height: 200
              }}
            />
            </Link>
            
            <h1>KEN ACQUAH</h1>
        </div>
            <Image
              fixed={data.avatar.childImageSharp.fixed}
            />
      </div>
      <MDXRenderer>{post.body}</MDXRenderer>
       <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer></footer>
    </Layout>
  )
}

export default AboutTemplate

export const aboutPageQuery = graphql`{
          avatar: file(absolutePath: {regex: "/headshot.png/" }) {
          childImageSharp {
          fixed(width: 350, height: 350) {
          ...GatsbyImageSharpFixed
        }
    }
  }
    mdx(fields: {slug: {eq: "/about/" } }) {
      body
      frontmatter {
          title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
